/*/////////////////////////////////////////////////////////////////
//    FileName: routeTrack.js
//    Author: Ho Da Zong
//    Created On: 04-Aug-2021
//    Last Modified On: 15-Oct-2021
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: route track DIV component, 
//                 will display at beside home page drawer if called.
*/ /////////////////////////////////////////////////////////////////

//import component
import React, { Component, Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  Polyline,
} from "react-leaflet";
import { CSVLink } from "react-csv";
import axios from "axios";
import { authMiddleWare } from "../util/auth";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { baseURL } from "../util/port.js";
import { Badge } from "@material-ui/core";

//export excel file header
const headers = [
  { label: "Date", key: "date" },
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Block", key: "block" },
  { label: "Release Point Coordinate", key: "pointCoord" },
  { label: "Invalid Point Coordinate", key: "invalidCoord" },
  { label: "Invalid Point Scanned", key: "invalidPoint" },
  { label: "Invalid Container", key: "invalidContainer" },
];

//for export multiple uses
var exportMultiple = [];

//main component class
class routeTrack extends Component {
  //props and state
  constructor(props) {
    super(props);

    this.state = {
      uiLoading: true,
      selectedDate: new Date(),
      users: [],
      person: "",
      polyline: [],
      center: [],
      showMap: false,
      invalidDatas: [],
      exportButton: true,
      viewOpen: false,
      isChecked: [],
      personArray: [],
      site: "",
      sites: [],
      selectedDays: [],
    };

    this.handleExportMultiplePerson =
      this.handleExportMultiplePerson.bind(this);
  }

  //will trigger once page load
  componentDidMount = async () => {
    //get all the user details from DB
    const authToken = localStorage.getItem("AuthToken");
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getAllUser`)
        .then((response) => {
          this.setState({
            users: response.data,
            //fills the checkbox with false
            isChecked: response.data.slice().fill(false),
            uiLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }

    //retrieve the sites data from db
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getSector`)
        .then((response) => {
          this.setState({
            sites: response.data,
            //uiLoading: false
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  getAllReleaseRecordDatePerson = async (todayDate, site, person) => {
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const formRequest = {
        site: site,
        person: person,
      };
      await axios
        .post(`${baseURL}/getAllReleaseRecordDatePerson`, formRequest)
        .then((response) => {
          var selectDate = [];
          var uniqueArray = [];
          //parse the pass in date to date format
          var date = new Date(todayDate.toString());

          for (var i = 0; i < response.data.length; i++) {
            var resultDate = new Date(response.data[i].date.seconds * 1000);

            if (
              date.getMonth() + 1 === resultDate.getMonth() + 1 &&
              date.getFullYear() === resultDate.getFullYear()
            ) {
              //if the return result month and year match the pass in value month and year then push it into an array
              selectDate.push(resultDate);
            }
          }

          // Loop through array values, to filter out those redundant date, and push those unique date in array
          for (var j = 0; j < selectDate.length; j++) {
            if (uniqueArray.indexOf(selectDate[j].getDate()) === -1) {
              uniqueArray.push(selectDate[j].getDate());
            }
          }

          //assign the unique date data into state array for patching later
          this.setState({
            selectedDays: uniqueArray,
            uiLoading: false,
          });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          console.log(err);
        });
    }
  };

  //display map after selected date and person and retrive data from DB
  displayMap = () => {
    const { classes } = this.props;
    //if got data retrieved in DB will show and render the map and data
    if (this.state.showMap === true) {
      return (
        <div>
          <MapContainer
            center={this.state.center}
            style={{ height: "600px", width: "100%" }}
            zoom={18}
            scrollWheelZoom={true}
          >
            <TileLayer
              attribution='OneMap | Map data &copy; contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>'
              url="https://maps-c.onemap.sg/v3/Default/{z}/{x}/{y}.png"
            />
            {
              //patch the dot marker on the map
              this.state.polyline.map((dot, index) => (
                <Marker position={dot}>
                  <Tooltip direction="top" opacity={1} permanent>
                    {index + 1}
                  </Tooltip>
                </Marker>
              ))
            }
            {/* draw the link line with polyline */}
            <Polyline
              pathOptions={{ color: "lime" }}
              positions={this.state.polyline}
            />
          </MapContainer>

          <Divider variant="middle" style={{ marginTop: 30, wifth: "100%" }} />
          <Paper className={classes.paperTable}>
            <Table stickyHeader className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Sector</TableCell>
                  <TableCell>Blocks</TableCell>
                  <TableCell>Release Point Coordinate</TableCell>
                  <TableCell>Point ID</TableCell>
                  <TableCell>Invalid Point Coordinate</TableCell>
                  <TableCell>Invalid Container</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  //map all the invalid datas retrieved
                  this.state.invalidDatas.map((item, i) => (
                    <Fragment>
                      <TableRow key={`row-${i}`}>
                        <TableCell>{item.site}</TableCell>
                        <TableCell>{item.block}</TableCell>
                        <TableCell>{item.pointCoord}</TableCell>
                        <TableCell>{item.invalidPoint}</TableCell>
                        <TableCell>{item.invalidCoord}</TableCell>
                        <TableCell>{item.invalidContainer}</TableCell>
                      </TableRow>
                    </Fragment>
                  ))
                }
              </TableBody>
            </Table>
          </Paper>
        </div>
      );
    } else {
      //if no map coordinate record return no record
      return (
        <Typography variant="h5" component="h5">
          No Records!
        </Typography>
      );
    }
  };

  //open export multiple person selection dialogbox
  handleExportMultiplePerson = async (data) => {
    this.setState({
      viewOpen: true,
    });
  };

  render() {
    const { classes } = this.props;

    const { viewOpen } = this.state;

    //handle site dropdown changing
    const handleSiteDropdown = async (event) => {
      this.setState({ site: event.target.value, uiLoading: true });
      var siteTemp = event.target.value;
      this.getAllReleaseRecordDatePerson(
        this.state.selectedDate,
        siteTemp,
        this.state.person
      );
    };

    //person dropdown and change the state value
    const handlePersonDropdownChange = async (event) => {
      this.setState({ person: event.target.value });
      var personTemp = event.target.value;
      this.getAllReleaseRecordDatePerson(
        this.state.selectedDate,
        this.state.site,
        personTemp
      );
    };

    //close the export multiple dialogbox
    const handleDialogExportMultipleViewClose = () => {
      this.setState({ viewOpen: false });
    };

    //change the state if date selected is change
    const handleDateChange = (date) => {
      this.setState({ selectedDate: date });
    };

    //calander month change
    const handleMonthChange = (date) => {
      this.getAllReleaseRecordDatePerson(
        date,
        this.state.site,
        this.state.person
      );
    };

    //calander year change
    const handleYearChange = (date) => {
      this.getAllReleaseRecordDatePerson(
        date,
        this.state.site,
        this.state.person
      );
    };

    //when export multiple person checkbox is checked will trigger this function to re-assign the value in the isChecked array, so that result data is tele
    const toggleCheckboxValue = (index) => {
      this.setState({
        isChecked: this.state.isChecked.map((v, i) => (i === index ? !v : v)),
      });
    };

    //loading UI spinner
    if (this.state.uiLoading === true) {
      return (
        <div className={classes.root}>
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </div>
      );
    } else {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />

          <div className={classes.topArrange}>
            <FormControl
              variant="outlined"
              style={{ width: "150px", paddingTop: 10, paddingRight: 10 }}
            >
              <InputLabel id="userSelection">Person</InputLabel>
              <Select
                labelId="userSelectionLabel"
                id="userSelect"
                value={this.state.person}
                onChange={handlePersonDropdownChange}
                color="primary"
                label="Person"
              >
                {
                  //patch the user data into the dropdown
                  this.state.users.map((person) => (
                    <MenuItem value={person.userId}>
                      {person.firstName}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              style={{ width: "150px", paddingTop: 10, paddingRight: 10 }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Sector
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.site}
                onChange={handleSiteDropdown}
                color="primary"
                label="Sector"
              >
                {
                  //patch the site data into the dropdown
                  this.state.sites.map((site) => (
                    <MenuItem value={site.site}>{site.site}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                style={{ marginRight: 10 }}
                disableToolbar
                variant="inline"
                format="dd-MM-yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Release Date"
                value={this.state.selectedDate}
                onChange={handleDateChange}
                onMonthChange={handleMonthChange}
                onYearChange={handleYearChange}
                color="primary"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                renderDay={(
                  day,
                  selectedDate,
                  isInCurrentMonth,
                  dayComponent
                ) => {
                  const date = new Date(day);
                  //patch the selected date from state selectedDays
                  const isSelected =
                    isInCurrentMonth &&
                    this.state.selectedDays.includes(date.getDate());

                  // You can also use our internal <Day /> component
                  return (
                    <Badge
                      overlap={"circular"}
                      badgeContent={isSelected ? "⚫" : undefined}
                    >
                      {dayComponent}
                    </Badge>
                  );
                }}
              />
            </MuiPickersUtilsProvider>

            <Button
              style={{ marginLeft: 15, marginTop: 20 }}
              variant="contained"
              size="small"
              color="primary"
              onClick={async () => {
                //click and get the data from DB and call displaymap component to display
                var date = new Date(this.state.selectedDate.toString());
                var month;
                var day;
                if (
                  date.getMonth() + 1 === 10 ||
                  date.getMonth() + 1 === 11 ||
                  date.getMonth() + 1 === 12
                ) {
                  month = date.getMonth() + 1;
                } else {
                  month = "0" + (date.getMonth() + 1);
                }

                if (
                  date.getDate() === 1 ||
                  date.getDate() === 2 ||
                  date.getDate() === 3 ||
                  date.getDate() === 4 ||
                  date.getDate() === 5 ||
                  date.getDate() === 6 ||
                  date.getDate() === 7 ||
                  date.getDate() === 8 ||
                  date.getDate() === 9
                ) {
                  day = "0" + date.getDate();
                } else {
                  day = date.getDate();
                }
                //all date patching
                var finaldate = date.getFullYear() + "-" + month + "-" + day;
                //start loading UI spinner
                this.setState({ uiLoading: true });
                //preparing to fetch api data
                const authToken = localStorage.getItem("AuthToken");
                var path = [];
                const formRequest = {
                  userId: this.state.person,
                  finaldate: finaldate,
                  site: this.state.site,
                };
                if (
                  this.state.person !== "" &&
                  this.state.selectedDate !== "" &&
                  this.state.site !== ""
                ) {
                  authMiddleWare(this.props.history);
                  if (authToken === null) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  } else {
                    axios.defaults.headers.common = {
                      Authorization: `${authToken}`,
                    };
                    await axios
                      .post(`${baseURL}/getSelectedUserRoute`, formRequest)
                      .then(async (response) => {
                        //after get all the user route data, draw the polyline path
                        for (var i = 0; i < response.data.length; i++) {
                          path.push([
                            response.data[i].latitude,
                            response.data[i].longitude,
                          ]);
                        }
                        //if no path data stop and show no record on map
                        if (path === "") {
                          this.setState({
                            showMap: false,
                            uiLoading: false,
                          });
                        } else {
                          //else go and retrieve the user invalid tries data also and display on the map
                          authMiddleWare(this.props.history);
                          if (authToken === null) {
                            alert(
                              "Your session is expired, please login to retry."
                            );
                            this.setState({ uiLoading: false });
                            this.props.setLogin(false);
                          } else {
                            this.state.personArray.push(this.state.person);
                            const formRequestTry = {
                              userId: this.state.personArray,
                              finaldate: finaldate,
                            };
                            if (authToken === null) {
                              alert(
                                "Your session is expired, please login to retry."
                              );
                              this.setState({ uiLoading: false });
                              this.props.setLogin(false);
                            } else {
                              axios.defaults.headers.common = {
                                Authorization: `${authToken}`,
                              };
                              await axios
                                .post(
                                  `${baseURL}/getSelectedUserInvalidData`,
                                  formRequestTry
                                )
                                .then((response) => {
                                  this.setState({
                                    invalidDatas: response.data,
                                  });
                                })
                                .catch((err) => {
                                  console.log(err);
                                  if (err.response.status === 403) {
                                    alert(
                                      "Your session is expired, please login to retry."
                                    );
                                    this.setState({ uiLoading: false });
                                    this.props.setLogin(false);
                                  }
                                });
                            }
                          }

                          this.setState({
                            polyline: path,
                            uiLoading: false,
                            center: path[0],
                            showMap: true,
                            exportButton: false,
                            personArray: [],
                          });
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        if (err.response.status === 403) {
                          alert(
                            "Your session is expired, please login to retry."
                          );
                          this.setState({ uiLoading: false });
                          this.props.setLogin(false);
                        }
                      });
                  }
                } else {
                  alert(
                    "Please select all date, site and person to display data!"
                  );
                  this.setState({ uiLoading: false });
                }
              }}
            >
              Display
            </Button>

            {/* export the selected user data to csv, if the date and person is not selected, button will no enabled */}
            <Button
              style={{ marginLeft: 15, marginTop: 20 }}
              variant="contained"
              size="small"
              color="primary"
              disabled={this.state.exportButton}
            >
              <CSVLink
                data={this.state.invalidDatas}
                style={{ textDecoration: "none", color: "white" }}
                headers={headers}
                filename={`InvalidTries.csv`}
              >
                Export
              </CSVLink>
            </Button>
            {/* export multiple button to trigger the multiple user selection dialogbox */}
            <Button
              style={{ marginLeft: 15, marginTop: 20 }}
              variant="contained"
              size="small"
              color="primary"
              onClick={() => this.handleExportMultiplePerson()}
            >
              Export Multiple
            </Button>
          </div>

          <div
            id="mapid"
            style={{ height: "600px", width: "200%", paddingTop: 20 }}
          >
            {
              //display map or no record shown
              this.displayMap()
            }
          </div>

          {/* the multiple selection user export multiple dialogbox component view */}
          <Dialog
            onClose={handleDialogExportMultipleViewClose}
            aria-labelledby="Export-Multiple-User-Data"
            open={viewOpen}
            fullWidth
            classes={{ paperFullWidth: classes.dialogeStyle }}
          >
            <DialogTitle
              id="Export-Multiple-User-Data"
              onClose={handleDialogExportMultipleViewClose}
            >
              Select Person:
            </DialogTitle>
            <DialogContent dividers>
              {
                //this dialog box is for export multiple, map the user value with checkbox control
                this.state.users.map((person, index) => (
                  <div id={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          key={index}
                          checked={this.state.isChecked[index]}
                          color="primary"
                          onClick={() => toggleCheckboxValue(index)}
                          name={person.firstName}
                          value={person.userId}
                        />
                      }
                      label={person.firstName}
                    />
                    <br />
                  </div>
                ))
              }

              {/* the button to export the selected multiple user */}
              <Button
                style={{ marginLeft: 850, marginTop: 20 }}
                variant="contained"
                size="small"
                color="primary"
                onClick={async () => {
                  //click and patching data then request API data in invalid tries DB table then patch data and export
                  var countSelected = 0;
                  for (var i = 0; i < this.state.isChecked.length; i++) {
                    if (this.state.isChecked[i] === true) {
                      countSelected = countSelected + 1;
                    }
                  }

                  if (countSelected === 0) {
                    alert("Please select at least one record.");
                  } else {
                    exportMultiple = [];
                    var date = new Date(this.state.selectedDate.toString());
                    var month;
                    var day;
                    if (
                      date.getMonth() + 1 === 10 ||
                      date.getMonth() + 1 === 11 ||
                      date.getMonth() + 1 === 12
                    ) {
                      month = date.getMonth() + 1;
                    } else {
                      month = "0" + (date.getMonth() + 1);
                    }

                    if (
                      date.getDate() === 1 ||
                      date.getDate() === 2 ||
                      date.getDate() === 3 ||
                      date.getDate() === 4 ||
                      date.getDate() === 5 ||
                      date.getDate() === 6 ||
                      date.getDate() === 7 ||
                      date.getDate() === 8 ||
                      date.getDate() === 9
                    ) {
                      day = "0" + date.getDate();
                    } else {
                      day = date.getDate();
                    }
                    var finaldate =
                      date.getFullYear() + "-" + month + "-" + day;
                    for (var j = 0; j < this.state.isChecked.length; j++) {
                      if (this.state.isChecked[j] === true) {
                        //get the selected person user ID
                        this.state.personArray.push(this.state.users[j].userId);
                      }
                    }
                    this.setState({ uiLoading: true });
                    const authToken = localStorage.getItem("AuthToken");
                    authMiddleWare(this.props.history);
                    if (authToken === null) {
                      alert("Your session is expired, please login to retry.");
                      this.setState({ uiLoading: false });
                      this.props.setLogin(false);
                    } else {
                      const formRequestTry = {
                        userId: this.state.personArray,
                        finaldate: finaldate,
                      };
                      if (authToken === null) {
                        alert(
                          "Your session is expired, please login to retry."
                        );
                        this.setState({ uiLoading: false });
                        this.props.setLogin(false);
                      } else {
                        //get invalid tries data
                        axios.defaults.headers.common = {
                          Authorization: `${authToken}`,
                        };
                        await axios
                          .post(
                            `${baseURL}/getSelectedUserInvalidData`,
                            formRequestTry
                          )
                          .then((response) => {
                            //add header
                            exportMultiple = [
                              [
                                "Date",
                                "First Name",
                                "Last Name",
                                "Block",
                                "Release Point Coordinate",
                                "Invalid Point Coordinate",
                                "Invalid Point Scanned",
                                "Invalid Container",
                              ],
                            ];

                            //loop and patch data
                            for (var i = 0; i < response.data.length; i++) {
                              exportMultiple.push([
                                response.data[i].date,
                                response.data[i].firstName,
                                response.data[i].lastName,
                                response.data[i].block,
                                '"' + response.data[i].pointCoord + '"',
                                '"' + response.data[i].invalidCoord + '"',
                                response.data[i].invalidPoint,
                                response.data[i].invalidContainer,
                              ]);
                            }
                            if (exportMultiple.length <= 1) {
                              exportMultiple.push([
                                "No Records!",
                                "----------",
                                "----------",
                              ]);
                            }
                            //start export the csv data, this will map the array value and put in csv content
                            let csvContent =
                              "data:text/csv;charset=utf-8," +
                              exportMultiple.map((e) => e.join(",")).join("\n");
                            //encode the file url
                            var encodedUri = encodeURI(csvContent);
                            //generate a link
                            var link = document.createElement("a");
                            //point the link to the encoded url
                            link.setAttribute("href", encodedUri);
                            //give the file define name
                            link.setAttribute("download", "InvalidTries.csv");
                            document.body.appendChild(link);
                            //simulate the link clicked and the csv file will auto download
                            link.click();
                          })
                          .catch((err) => {
                            console.log(err);
                            if (err.response.status === 403) {
                              alert(
                                "Your session is expired, please login to retry."
                              );
                              this.setState({ uiLoading: false });
                              this.props.setLogin(false);
                            }
                          });
                      }
                    }

                    this.setState({
                      uiLoading: false,
                      personArray: [],
                      viewOpen: false,
                    });
                  }
                }}
              >
                Export
              </Button>
            </DialogContent>
          </Dialog>
        </main>
      );
    }
  }
}

//style
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: {
    minWidth: 470,
    borderRadius: 25,
    height: 100,
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  topArrange: {
    //marginLeft: '70%'
  },
  paperTable: {
    width: "100%",
    marginTop: theme.spacing(2),
    overflowX: "auto",
    maxHeight: 200,
  },
  table: {
    minWidth: 700,
  },
  dialogeStyle: {
    maxWidth: "50%",
  },
});

//export component to other
export default withStyles(styles)(routeTrack);
